.payment-status {
  display: flex;
  height: 100vh;

  text-align: center;

  overflow-y: hidden;

  .container {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    width: 100%;
  }

  .content-wrapper {
    margin: 0 auto;
  }

  .title-heading {
    font-size: 1.17em;
  }

  .btn-container {
    position: relative;

    .back-btn {
      position: absolute;
      left: 0;
    }
  }

  .primary-btn {
    position: absolute;
    bottom: 0;
    left: 0;

    margin: 25px;
    width: calc(100% - 50px);

    background-color: $yellow;
  }
}

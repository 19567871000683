.transactions {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  background-color: #f7f7f7;

  .transactions-content {
    padding: 38px;
    overflow: hidden;

    flex: 1;
  }

  .datatable-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;

    background-color: #ffffff;
    border-radius: 12px;

    flex: 1;

    > div:nth-child(1) {
      height: 100%;
      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        height: 100%;

        .rdt_TableBody {
          overflow: auto;

          flex: 1;
        }
      }
    }
  }

  .datatable-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 48px;
    padding-bottom: 24px;

    border-bottom: 5px solid #f2f4f6;

    .datatable-header-label-wrapper {
      display: flex;
      flex-direction: column;

      font-family: $font-mplusrounded;

      .datatable-header-label {
        font-size: 24px;
        line-height: 36px;
        color: $default-text;
      }

      .datatable-header-description {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: $dark-gray;
      }
    }

    .datatables-actions-wrapper {
      display: flex;
      align-items: center;
      padding: 0;

      .export-button {
        display: flex;
        align-items: center;
        margin-left: 16px;

        background: transparent;
        border: none;
        outline: none;

        cursor: pointer;

        .export-icon {
          margin-right: 6px;

          color: $link;
        }

        .export-label {
          font-family: $font-mplusrounded;
          font-size: 14px;
          font-weight: 500;
          color: $link;
        }
      }
    }
  }
}

.shared-link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  background-color: $yellow;

  overflow-y: hidden;

  .container {
    text-align: center;
  }

  .content-wrapper {
    margin: auto;

    &:first-child {
      .img {
        margin: 25px auto;
      }

      .title-heading {
        font-size: 20px;
        font-weight: 600;
      }

      .desc {
        margin: 10px auto 40px;
        width: 55%;

        font-family: $font-mplusrounded;
        font-size: 14px;
      }
    }

    &:last-child {
      .mobile {
        display: none;
      }

      .desktop {
        .btn-google {
          margin-right: 20px;
        }
      }
    }
  }
}

@include respond-to(md) {
  .shared-link {
    .content-wrapper {
      &:first-child {
        .desc {
          width: 55%;
        }
      }
    }
  }
}

@include respond-to(sm-down) {
  .shared-link {
    .desktop {
      display: none;
    }

    .container {
      padding: 0;
    }

    .content-wrapper {
      &:first-child {
        .img {
          width: 100px;
          height: 137.86px;
        }

        .title-heading {
          font-size: 18px;
        }

        .desc {
          width: 100%;
        }
      }

      &:last-child {
        .mobile {
          display: block;
        }
      }
    }
  }
}

@include respond-to(xs) {
  .shared-link {
    .content-wrapper {
      width: 70%;
    }
  }
}

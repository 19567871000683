.App {
  position: relative;
}

.admin-login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;

  background-color: $background-color;

  .footer {
    position: absolute;
    bottom: 24px;
    left: 50%;

    transform: translateX(-50%);
  }

  .btn-login {
    div {
      display: flex;
    }
  }

  .white-box {
    padding: 66px 170px;
    // width: 330px;
    max-width: 100%;

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .back-holder {
      display: none;
    }
  }

  .login-content-wrapper {
    width: 325px;
  }

  .title {
    margin: 20px 0 32px;

    font-family: $font-mplusrounded;
    font-size: 18px;
    letter-spacing: .15px;
    color: $midnight-blue;
  }

  .input-holder {
    position: relative;

    margin-bottom: 30px;

    .eye-icon {
      display: flex;
      align-items: center;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .input {
    padding: 25px 15px 7px;
    width: 100%;
    height: 54px;

    font-family: $font-mplusrounded;
    font-size: 16px;

    &:focus {
      border: 1px solid $midnight-gray;
      outline: initial;
    }

    &:focus ~ .placeholder,
    &:not([value=""]):not(:focus):valid ~ .placeholder,
    &:not([value=""]):not(:focus):invalid ~ .placeholder {
      top: 5px;
      bottom: 10px;
      left: 16px;

      font-size: 14px;

      opacity: 1;
    }
  }

  .placeholder {
    position: absolute;
    top: 15px;
    left: 16px;

    font-family: $font-mplusrounded;
    font-size: 17px;
    color: $gray;

    pointer-events: none;
    transition: .2s ease all;
  }

  .eye-icon {
    position: absolute;
    top: 50%;
    right: 0;

    display: flex;
    width: 40px;
    height: 100%;

    cursor: pointer;
    transform: translateY(-50%);
  }

  .btn {
    width: 100%;
  }

  .denied {
    position: relative;

    padding: 66px 130px 177px;
    width: 410px;

    .back-holder {
      position: absolute;
      top: 37px;
      left: 39px;

      display: block;
      padding: 0;
    }
  }

  .access-denied {
    margin-top: 60px;

    text-align: center;

    .text {
      padding: 14px 15px;

      font-size: 16px;
      color: $brink-pink;

      background-color: $lavender-blush;
      border-radius: 8px;
    }
  }
}

@include respond-to(sm-down) {
  .admin-login {
    .white-box {
      padding: 15px 15px 50px;
      margin: 24px 24px 126px;
      width: 100%;

      border-radius: 0;
    }
  }
}

@import './common.scss';
@import './login';
@import './dashboard';
@import './transactions';
@import './transaction-details';
@import './payouts';
@import './payout-details';
@import './payout-history';

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

.rdt_TableRow {
  cursor: pointer;
}

.rdt_Table {
  padding: 0 16px;

  // border-radius: 0 0 12px;
}

.rdt_Pagination {
  background-color: transparent;
  border: none !important;
}

button {
  font-family: $font-mplusrounded;
}

.row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.flex1 {
  flex: 1;
}

.dashboard {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;

  background-color: #f7f7f7;

  .panel-wrapper {
    display: flex;

    background-color: white;
    border-radius: 0 48px 0 0;
    box-shadow: 0 16px 21px 6px rgba(235, 235, 235, .5);
  }

  & ~ footer {
    right: initial;
    left: 52px;
  }
}

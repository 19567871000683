.sidepanel {
  display: flex;
  flex-direction: column;
  padding: 57px 18px 0 18px;
  width: 270px;
  height: 100%;

  background-color: #ffffff;
  border-radius: 0 48px 0 0;
  box-shadow: 0 16px 21px 6px rgba(235, 235, 235, .5);
  .logo-wrapper {
    text-align: center;
  }

  .user-profile {
    display: flex;
    align-items: center;
    margin: 30px 0 31px;

    color: $midnight-gray;

    .user-avatar {
      width: 32px;
      height: 32px;
    }

    .text {
      margin-left: 9px;

      font-size: 15px;
    }

    .display-name {
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .menu-item-content-wrapper {
    position: relative;

    display: flex;
    flex-direction: row;
    width: 100%;

    flex: 1;
  }

  .menu-items {
    font-family: $font-mplusrounded;
    color: $midnight-blue;

    flex: 1;

    .menu-item {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 14px;
      padding-right: 28px;
      margin-bottom: 16px;

      border-radius: 8px;

      cursor: pointer;

      &.active {
        color: #ffffff;

        background-color: $midnight-blue;
        .menu-item-arrow {
          border-left-color: #ffffff;
        }
      }

      &.expanded {
        .menu-item-arrow {
          border-top-color: #ffffff;
          border-left-color: transparent;

          transform: translateY(-50%) translateX(-2px);
        }
      }

      .menu-item-content {
        display: flex;
        flex-direction: column;

        flex: 1;
      }

      .menu-item-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
      }

      .menu-item-description {
        font-size: 10px;
        line-height: 15px;
      }

      .menu-item-icon {
        margin-top: 2px;
        margin-right: 9px;

        color: $icon;
      }

      .menu-item-arrow {
        position: absolute;
        top: 50%;
        right: -18px;

        border: 5px solid transparent;
        border-left-color: $midnight-blue;

        transform: translateY(-50%);
      }
    }

    .child-items {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      margin-left: 24px;
      width: 100%;

      .child-item {
        position: relative;

        padding: 6px 0;

        .child-item-label {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: .25px;
          line-height: 21px;
          color: #ffffff;

          opacity: .75;
        }

        .child-item-arrow {
          position: absolute;
          top: 50%;
          right: 6px;

          display: none;

          border: 5px solid transparent;
          border-left-color: #ffffff;

          // transform: translateY(-50%);
        }

        &.active {
          .child-item-label {
            font-weight: 500;

            opacity: 1;
          }

          .child-item-arrow {
            display: block;
          }
        }
      }
    }
  }
}

.content-panel {
  position: relative;

  padding: 62px 32px 0 26px;
  width: 350px;

  &:after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100px;

    box-shadow: 0 -153px 20px -72px $white inset;

    opacity: .5;
  }

  .title {
    margin-bottom: 0;

    font-size: 24px;
    color: $midnight-gray;
  }

  .desc {
    margin: 4px 0 16px;

    font-size: 15px;
  }

  .search-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-bar {
    position: relative;

    display: flex;
    width: 305px;
  }

  input {
    padding: 15px 15px 15px 42px;
    width: 100%;

    font-size: 15px;

    border: 1px solid $light-blue;
    border-radius: 32px;

    &:focus {
      outline: initial;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 15px;

    transform: translateY(-50%);
  }

  .total-sort {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 13px;

    font-size: 13px;
  }

  .total-pending {
    color: $midnight-gray;
  }

  .sort {
    color: $medium-gray;
  }

  .result-list {
    position: relative;

    padding-right: 22px;
    height: 66vh;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $pale-blue;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;

      background: $midnight-blue;
      border-right: none;
      border-left: none;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 343px;

      background: transparent;
    }
  }

  .result-item {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 23px;
    width: 100%;

    text-align: initial;

    background: none;
    border: 1px solid $solitude;
    border-bottom: 0;
    border-radius: 4px;

    cursor: pointer;

    & > * {
      pointer-events: none;
    }

    &:focus {
      outline: initial;
    }

    &:last-child {
      margin-bottom: 100px;
    }

    &.selected-user {
      background-color: $solitude;
    }

    .user-profile {
      width: 41px;
      height: 41px;
      flex: none;
    }

    .profile-img {
      width: 100%;
      height: 100%;

      background-color: $medium-gray;
      border-radius: 50%;

      object-fit: cover;
    }

    .info-holder {
      margin: 0 11px 0;
    }

    .name,
    .username,
    .time {
      margin: 0;

      font-family: $font-mplusrounded;
    }

    .name {
      font-size: 15px;
    }

    .username {
      font-size: 13px;
    }

    .time {
      font-size: 11px;
      white-space: nowrap;
    }
  }
}

.profile-info {
  position: relative;

  padding: 52px 55px 52px 60px;
  margin: auto;
  width: 39%;

  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(31, 26, 84, .06);

  .user-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
  }

  .user-img-holder {
    margin-right: 16px;
    width: 55px;
    height: 55px;

    .img {
      width: 100%;
      height: 100%;

      background-color: $medium-gray;
      border-radius: 50%;

      object-fit: cover;
    }
  }

  .name,
  .date {
    margin: 0;
  }

  .name {
    margin-bottom: 5px;

    font-size: 16px;
  }

  .date {
    font-size: 13px;
  }

  .heading-holder {
    position: relative;

    display: flex;
    align-items: center;
  }

  .title {
    margin-left: 8px;

    font-size: 16px;
  }

  .subtitle {
    position: absolute;
    right: 0;

    font-size: 10px;
  }

  .info-wrapper {
    margin-bottom: 16px;
  }

  .info-list {
    padding: 15px 0 6px;
    margin: 0;

    border-bottom: 1px solid;

    list-style: none;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .info-title {
    margin: 0 250px 0 0;

    font-size: 15px;
    font-weight: 600;
  }

  .info-data {
    margin: 0;

    font-size: 14px;
    line-height: 21px;
    text-align: end;
  }

  .button-holder {
    display: flex;
    justify-content: flex-end;
  }

  .btn-outline {
    margin-right: 14px;
  }

  .btn-yellow {
    width: 191px;
  }
}

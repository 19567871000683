.btn {
  position: relative;

  display: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 11px 32px;

  font-family: $font-mplusrounded;
  font-size: 15px;
  font-weight: 600;
  color: $midnight-gray;
  text-align: center;
  white-space: pre;

  background: none;
  border: 0;
  border-radius: 4px;
  outline: initial;

  cursor: pointer;
  transition: all .2s ease-in-out;

  box-sizing: border-box;
}

.btn-light {
  background-color: $light-gray;
  border: 1.5px solid $light-gray;
}

.btn-yellow {
  background-color: $yellow;
  border: 1.5px solid $yellow;
}

.btn-outline {
  background-color: transparent;
  border: 1.5px solid $midnight-blue;
}

.back-btn {
  padding: 0;

  border-color: transparent;
}

.btn-filter {
  padding: 0;
  width: 50px;
  height: 50px;

  border: 1px solid $light-blue;
  border-radius: 50%;
}

.btn-view {
  padding: 0;
  width: auto;
  min-width: auto;
  height: 100%;

  font-size: 14px;
  font-weight: 600;
  color: $blue;
  text-decoration: initial;

  .icon {
    margin-right: 5px;
  }
}

.transaction-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  font-family: $font-mplusrounded;
  color: $default-text;

  background-color: #f7f7f7;

  flex: 1;
}


.content-wrapper {
  padding: 40px;
  overflow: auto;

  flex: 1;

  .content-header {
    padding: 40px 49px 24px 49px;

    background-color: #ffffff;
    border-bottom: 5px solid $zircon-light;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    .content-header-title {
      margin-bottom: 6px;

      font-size: 24px;
      font-weight: 400;
      line-height: 36px;

      a {
        &:first-child {
          color: $medium-gray;
          text-decoration: none;
        }
      }
    }

    .content-header-sub {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: $dark-gray;
    }

    p {
      margin: 0;
    }
  }

  .content {
    display: grid;
    padding: 49px;
    padding-bottom: 40px;

    background-color: #ffffff;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    grid-template-columns: "1fr 1fr";
    grid-template-rows: "1fr 1fr";
    grid-gap: 24px;

    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 400px;
    }
  }

  .section {
    padding: 24px;

    border: 1px solid #cacbcc;
    border-radius: 8px;

    flex: 1;

    .section-content {
      padding-top: 16px;
    }

    .section-info-wrapper {
      display: grid;
      margin-bottom: 12px;

      grid-template-columns: 225px 1fr;

      .section-info-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }

      .section-info-value {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: $placeholder;
        &.net-amount {
          font-weight: 500;
        }
        &.payment-status-value {
          position: relative;

          padding-left: 12px;

          text-transform: capitalize;
          &::before {
            content: "";

            position: absolute;
            top: 50%;
            left: 0;

            width: 6px;
            height: 6px;

            border-radius: 6px;

            transform: translateY(-50%);
          }
          &.paid::before {
            background-color: $shamrock;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .section-header-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }

  .section-header-icon {
    margin-right: 8px;
  }

  .order-details-section {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .payment-note {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    margin-top: 8px;
    width: 100%;

    background-color: $solitude;
    border-radius: 8px;

    span {
      padding: 0;
      margin: 0;
      margin-left: 2.5px;

      font-size: 11px;
      strong {
        font-weight: 500;
      }
    }
  }
}

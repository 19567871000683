@charset "UTF-8";

// Configuration and helpers
@import "utils/variables", "utils/functions", "utils/mixins";

@import "base/typography";

@import "components/buttons", "components/input", "components/white-box",
  "components/sidepanel", "components/filter", "components/modal",
  "components/spinner", "components/footer";

@import "pages/webapp/download", "pages/webapp/payment-status";
@import "pages/admin";

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.subtitle-2 {
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: .1px;
  line-height: 18px;
}

.display-5 {
  margin: 0;

  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  color: #2e3034;
}
